<template lang="pug">
  v-navigation-drawer(
    left clipped
    fixed
    app
    :permanent='!isMobile'
    :expand-on-hover='isDrawerMini'
    :temporary='isMobile'
    :mini-variant='isDrawerMini'
  )
    //- v-list(shaped)
      v-list-item-group(color="primary")
        v-list-item
          v-list-item-icon.my-3.mr-3
            v-icon mdi-format-list-checks
          v-list-item-content
            v-list-item-title Getting Started
          v-list-item-action(@click='closeDrawer')
            v-icon() mdi-close
    //- v-divider

    v-list(v-if='isMobile && appReady' shaped)
      v-list-item.user-profile-btn(
        router-link :to='componentConfig.userSettings.isUserProfileShown ? userProfileLink : ""')
        v-list-item-icon.my-3.mr-3
          v-icon()
            | mdi-account-circle
        v-list-item-content
          v-list-item-title.user-display-name
            | {{ userDisplayName }}
          v-list-item-subtitle.user-org
            | {{ userOrganizationName }}

      switchOrganization(v-if="componentConfig.userSettings.isSwitchOrganizationShown")
        template(v-slot:customActivator='')
          v-list-item.switch-org-btn
            v-list-item-icon.my-3.mr-3
              v-icon mdi-account-switch
            v-list-item-title Switch Organization
    v-divider(v-if='appReady')

    v-list(v-if='appReady' shaped)
      v-list-item-group(color='primary')
        v-list-item.dashboard(v-if='componentConfig.isReportsShown' router-link to='/dashboard')
          v-list-item-icon.my-3.mr-3
            v-icon mdi-view-dashboard
          v-list-item-content
            v-list-item-title Dashboard

        v-list-item.plans(v-if="can('proposal').read.default" router-link to='/plans')
          v-list-item-icon.my-3.mr-3
            v-icon mdi-calendar-text-outline
          v-list-item-content
            v-list-item-title Plans

        v-list-item.campaigns(v-if="can('campaign').read.default" router-link to='/campaigns')
          v-list-item-icon.my-3.mr-3
            v-icon mdi-book-open
          v-list-item-content
            v-list-item-title Campaigns

        v-list-item.creatives(v-if="can('creative').read.default" router-link to='/creatives')
          v-list-item-icon.my-3.mr-3
            v-icon mdi-image-size-select-actual
          v-list-item-content
            v-list-item-title Creatives

        v-list#inventory-list(v-if="componentConfig.audiences.canSaveLoadAudience")
          v-list-group(eager)
            template(v-slot:activator='')
              v-list-item-icon.my-3.mr-3
                v-icon mdi-monitor
              v-list-item-title Inventory
            v-list-item.auction-packages(v-if='canSeeAuctionPackage' router-link to='/auction-packages' dense exact)
              v-list-item-icon.my-3.mr-3
                v-icon mdi-circle-medium
              v-list-item-title Auction Packages
            v-list-item.packages(router-link to='/packages' dense exact)
              v-list-item-icon.my-3.mr-3
                v-icon mdi-circle-medium
              v-list-item-title Packages
            v-list-item.deals(v-if="canSeeDeals" router-link to='/deals' dense exact)
              v-list-item-icon.my-3.mr-3
                v-icon mdi-circle-medium
              v-list-item-title Deals

        v-list-item.reports(v-if='componentConfig.isReportsShown' router-link to='/reports')
          v-list-item-icon.my-3.mr-3
            v-icon mdi-finance
          v-list-item-content
            v-list-item-title Reports

    v-divider(v-if='appReady')

    v-list(v-if='appReady && canSeeAdminOptions' shaped)
      v-list-group(eager)
        template(v-slot:activator='')
          v-list-item-icon.my-3.mr-3
            v-icon mdi-cog
          v-list-item-title Admin
        v-list-item.settings-btn(v-if="componentConfig.userSettings.isSettingsShown" router-link :to='organizationSettingsLink' dense exact)
          v-list-item-icon.my-3.mr-3
            v-icon mdi-circle-medium
          v-list-item-title Settings
        v-list-item.manage-users(v-if="can('user').read.allUsers" router-link to='/users' dense exact)
          v-list-item-icon.my-3.mr-3
            v-icon mdi-circle-medium
          v-list-item-title Users Access
        v-list-item.manage-orgs(v-if="can('organization').read.allOrgs" router-link to='/organizations' dense exact)
          v-list-item-icon.my-3.mr-3
            v-icon mdi-circle-medium
          v-list-item-title Organizations Access
        //- v-list-item(router-link to='#' dense exact )
          v-list-item-icon.my-3.mr-3
            v-icon mdi-circle-medium
          v-list-item-title Advertisers
        v-list-item.billing(v-if='componentConfig.isBillingShown' router-link to='/billing' dense exact)
          v-list-item-icon.my-3.mr-3
            v-icon mdi-circle-medium
          v-list-item-content
            v-list-item-title Billing
        //- v-list-item(router-link to='#' dense exact)
          v-list-item-icon.my-3.mr-3
            v-icon mdi-circle-medium
          v-list-item-title Change history

    v-divider(v-if='appReady && canSeeAdminOptions')
    v-list(v-if='appReady' shaped)
      v-list-item(router-link to='/logout')
        v-list-item-icon.my-3.mr-3
          v-icon mdi-logout-variant
        v-list-item-content
          v-list-item-title Log Out

    v-divider(v-if='appReady')
    div.py-4.px-2.footer(v-show='canSeeFooter')
      a.info--text.mx-1.text-caption(href='https://broadsign.com/programmatic-privacy-policy/' target="_blank")
        | Platform Policy
      a.info--text.mx-1.text-caption(href='https://broadsign.com/privacy-policy/' target="_blank")
        | Privacy Policy
      a.info--text.mx-1.text-caption(href='/terms-conditions' target="_blank")
        | Terms
      div.copyright.mx-1.text-caption(v-if='componentConfig.branding.isCopyrightShown')
        | {{ "© Broadsign Advertising, Inc. " + year }}
</template>
<style lang="stylus" scoped>
#inventory-list
  padding: 0px
</style>
<script>
import switchOrganization from '@/components/switchOrganization.vue'
import componentConfigService from '@/services/componentConfig'
import helpers from '@/services/helpers.service'

export default {
  components: {
    switchOrganization
  },
  props: ['appReady', 'drawer', 'userDisplayName', 'userProfileLink', 'userOrganization'],
  data () {
    return {
      isDrawerMini: true
    }
  },
  created () {
    this.isDrawerMini = !helpers.isAppDrawerOpen(this.isMobile, true)
  },
  watch: {
    drawer: function (newVal) {
      this.isDrawerMini = !newVal
    }
  },
  computed: {
    userOrganizationName () {
      return this.userOrganization.name || ''
    },
    canSeeAuctionPackage () {
      return this.$store.getters['general/isAuctionPackageMenuVisible']
    },
    organizationSettingsLink () {
      return '/organizationsSettings/' + this.userOrganization.id
    },
    canSeeDeals () {
      return !!this.userOrganization.seatName
    },
    year () {
      const date = new Date()
      return date.getFullYear()
    },
    isMobile () {
      return this.$vuetify.breakpoint.xsOnly
    },
    canSeeFooter () {
      return this.appReady && (this.isMobile || !this.isDrawerMini)
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    },
    canSeeAdminOptions () {
      return this.componentConfig.userSettings.isSettingsShown || this.componentConfig.isBillingShown || this.can('user').read.allUsers || this.can('organization').read.allOrgs
    }
  },
  methods: {
    can (entity) {
      return this.$store.getters['user/permissions'](entity)
    }
  }
}
</script>
