<template lang="pug">
  div.mb-3(v-if='isVisible')
    v-row.my-2.fee(v-for='fee in Fees' :key='fee.layoutOrder + `-${Label.toLowerCase()}`' align='center' :class='fee.cssClass')
      v-col.fee-label(cols='auto') {{ fee.secondaryLabel }}
      v-col.fee-value(cols=4 md=3 lg=4)
        v-text-field(
          :class="`fee-value-field-${Label.toLowerCase()}`"
          :key="`${Type}-${refreshKey}`"
          dense single-line filled rounded hide-details
          placeholder='0.00'
          type='number'
          step='.01'
          :suffix='Suffix'
          :rules='[rules.positive]'
          :value='fee.displayedValue'
          @blur='handleUpdateValue(fee.layoutOrder, Type,  ...arguments)'
          )
      v-col.fee-custom-label(cols='auto')
        v-text-field(
          v-if='fee.isLabelFixed'
          dense single-line hide-details disabled
          append-outer-icon='mdi-lock'
          :value='fee.label'
          )
        v-text-field(
          v-else
          placeholder='Custom Label'
          dense single-line hide-details
          append-outer-icon='mdi-close'
          :value='fee.label'
          @blur='handleUpdateLabel(fee.layoutOrder, fee.type, ...arguments)'
          @click:append-outer='handleDeleteFee(fee.layoutOrder, fee.type)'
          )

    v-btn.mt-3(:class="`add-${Label.toLowerCase()}-fee-btn`" text color='primary' @click="handleAddFee(Type)" :disabled='Fees.length >= 5') + Add {{ Label }} Fee
</template>

<script>

export default {
  props: {
    Fees: {
      type: Array
    },
    Type: {
      type: String
    },
    Label: {
      type: String
    },
    Suffix: {
      type: String
    },
    defaultFee: {
      type: Array
    },
    isVisible: {
      type: Boolean
    },
    refreshKey: {
      type: Number
    }
  },
  data () {
    return {
      rules: {
        positive: v => v >= 0 || 'Positive numbers only'
      },
      defaultFees: [
        ...this.defaultFee
      ],
      fees: []
    }
  },
  methods: {
    handleUpdateValue (layoutOrder, type, ...args) {
      this.$emit('updateValue', layoutOrder, type, ...args)
    },
    handleUpdateLabel (layoutOrder, type, ...args) {
      this.$emit('updateLabel', layoutOrder, type, ...args)
    },
    handleDeleteFee (layoutOrder, type) {
      this.$emit('deleteFee', layoutOrder, type)
    },
    handleAddFee (type) {
      this.$emit('addFee', type)
    }
  }
}
</script>
