import Vue from 'vue'
import audienceAPI from '@/services/audience.api'
import reportingAPI from '@/services/reporting.api'
import audienceService from '../services/audience.service'
import geoService from '@/services/geo.service'
import tracking from '@/services/tracking'
import moment from 'moment'
import DatetimeFormattingEnum from '../enums/modules/DatetimeFormattingEnum'

const audienceStateFactory = () => {
  return {
    geoTargets: [],
    mobileTargets: [],
    targetStateController: [],
    screenSize: [],
    screenTargets: [],

    // API Format
    segments: {},
    targeting: [],
    geography: {},

    // Forecast VENUES
    forecastVenuesLoading: true,
    forecastVenues: {
      venues: []
    },

    // Forecast INVENTORY
    forecastInventoryLoading: true,
    forecastInventory: {
      positions: []
    },

    // Forecast MERGED
    forecastLoading: true,
    forecast: {
      bidRanges: [
        {
          cpmps: 0,
          percentile: 10
        },
        {
          cpmps: 0,
          percentile: 99
        },
        {
          cpmps: 0,
          percentile: 100
        }
      ],
      environments: [],
      formats: [],
      impressions: {
        value: 0
      },
      inventory: {
        numberOfScreens: 0,
        numberOfVenues: 0
      },
      spending: [
        {
          percentile: 10,
          amount: 0.0000
        },
        {
          percentile: 99,
          amount: 0.0000
        },
        {
          percentile: 100,
          amount: 0.0000
        }
      ],
      budget: null
    },

    // Schedule Specific
    startDate: moment().format(DatetimeFormattingEnum.DATE_ONLY),
    startTime: moment().add(1, 'hours').startOf('hours').format(DatetimeFormattingEnum.TIME_ONLY),
    endDate: moment().add(4, 'weeks').format(DatetimeFormattingEnum.DATE_ONLY),
    endTime: '11 PM',

    // Inventory Map/list sync
    mapOptions: null,
    initialMapOptions: null,
    filterAsImoveMap: true,
    pagination: {
      sortBy: ['environment'],
      sortDesc: [false],
      page: 1,
      take: 10,
      skip: 0,
      query: null,
      filtering: false,
      hasMorePages: false
    },

    focusedGeoInfo: { index: -1, centerMap: false },
    initialTargeting: [],
    usePublicExchange: null,
    currency: null,
    viewPort: {
      minLatitude: 0,
      minLongitude: 0,
      maxLatitude: 0,
      maxLongitude: 0,
      zoom: 3
    }
  }
}

export default {
  namespaced: true,
  state: audienceStateFactory(),
  getters: {
    targetsGroups: (state, getters, rootState, rootGetters) => {
      const exchange = rootGetters['general/currentPageExchange']
      return rootGetters['general/allTargetGroups'][exchange.key]
    },
    targets: (state, getters, rootState, rootGetters) => {
      const exchange = rootGetters['general/currentPageExchange']
      if (!exchange.key) { return [] }

      const targets = rootGetters['general/allTargets'][exchange.key]

      return !rootGetters['general/marketDefaultValues'].canSeeScreens
        ? targets.filter(x => !['screensizerange', 'creativeformat'].includes(x.name))
        : targets
    },
    forecastVenues: state => { return state.forecastVenues },
    forecastVenuesLoading: state => { return state.forecastVenuesLoading },

    forecastInventory: state => { return state.forecastInventory },
    forecastInventoryLoading: state => { return state.forecastInventoryLoading },

    forecast: state => { return state.forecast },
    forecastLoading: state => { return state.forecastLoading },

    activeMobileTargetGroups: (state, getters) => {
      return audienceService.getActiveMobileTargetGroups(getters.targetsGroups)
    },
    deprecatedMobileTargetGroups: (state, getters) => {
      return audienceService.getDeprecatedMobileTargetGroups(getters.targetsGroups)
    },
    targetStateController: (state, getters) => targetKey => {
      return audienceService.isTargetGroupExpanded({
        targetKey,
        currentState: state.targetStateController,
        targetGroups: getters.targetsGroups,
        targeting: state.targeting
      })
    },

    // MOBILE
    selectedMobileTargets: (state, getters) => (targetKey, isDeprecated = false) => {
      const groups = isDeprecated ? getters.deprecatedMobileTargetGroups : getters.activeMobileTargetGroups
      return audienceService.selectedTargetsPerGroup(targetKey, groups, state.targeting)
    },

    // SCREEN size & Orientation
    screenTargets: (state, getters) => {
      const screenSizeTarget = getters.targets.find(target => target.name === 'screensizerange')
      return audienceService.screenSizeFormatting(screenSizeTarget)
    },
    availableScreenInfo: (state, getters) => {
      const info = { orientations: [], showSizes: false }
      const sizes = getters.targets && getters.targets.find(target => target.name === 'screensizerange') ? getters.targets.find(target => target.name === 'screensizerange').targetValues : []
      if (sizes.length > 1) {
        info.showSizes = true
      }
      const orientation = getters.targets && getters.targets.find(target => target.name === 'creativeformat') ? getters.targets.find(target => target.name === 'creativeformat').targetValues.filter(x => ['Portrait', 'Landscape'].includes(x.value)) : []
      if (getters.targets && orientation.length) {
        info.orientations = orientation.map(x => x.name)
      }
      return info
    },
    showScreenSection: (state, getters) => {
      const screenInfo = getters.availableScreenInfo
      return screenInfo.showSizes || screenInfo.orientations.length > 1
    },
    selectedScreenSize: state => {
      const sizeTarget = state.targeting.find(x => x.target === 'screensizerange')
      if (sizeTarget) {
        return sizeTarget.targetValues
      } else {
        return []
      }
    },
    publishersList: (state, getters) => {
      return audienceService.getListOfSimpleTarget('Publishers', getters.targets, state.initialTargeting)
    },
    selectedPublishers: state => {
      return audienceService.getSelectedScreenTarget('Publishers', state.targeting)
    },
    creativeFormatsList: (state, getters) => {
      return audienceService.getGroupedAndUngroupedListOfSimpleTargets('CreativeFormats', getters.targets, state.initialTargeting)
    },
    selectedCreativeFormats: state => {
      return audienceService.getSelectedScreenTarget('CreativeFormats', state.targeting)
    },
    measurementPartnersList: (state, getters) => {
      return audienceService.getListOfSimpleTarget('MeasurementPartners', getters.targets, state.initialTargeting)
    },
    selectedMeasurementPartners: state => {
      return audienceService.getSelectedScreenTarget('MeasurementPartners', state.targeting)
    },
    creativeSizesList: (state, getters) => {
      return audienceService.getListOfCreativeSizes(getters.targets, state.initialTargeting)
    },
    selectedCreativeSizes: state => {
      return audienceService.getSelectedScreenTarget('CreativeSizes', state.targeting)
    },

    // GEO

    selectedGeoTargets: (state, getters, rootState, rootGetters) => {
      let distanceUnit
      if (rootGetters['general/currentPageExchange'].key) {
        distanceUnit = rootGetters['general/marketValueFromExchange'](rootGetters['general/currentPageExchange'].key).distanceUnit
      } else {
        distanceUnit = rootGetters['general/marketDefaultValues'].distanceUnit
      }

      return state.geography ? geoService.geoFromApiFormatting(state.geography, distanceUnit) : []
    },

    geography: state => {
      return state.geography
    },

    // ENV

    getEnvironmentsApiFormat: state => {
      return state.segments
    },

    getEnvironmentsForecastApiFormat: state => {
      // Optimize query if every group contain only one filter
      if (state.segments?.groups?.every(group => group?.filters?.length === 1)) {
        return {
          operation: 'and',
          groups: [{
            operation: 'or',
            filters: state.segments.groups.map(group => {
              return group.filters[0]
            })
          }]
        }
      }

      return state.segments
    },

    selectedTargets: state => {
      return state.targeting
    },

    envsTaxonomy: (state, getters, rootState, rootGetters) => {
      return rootGetters['general/allTaxonomies'][rootGetters['general/currentPageExchange'].key]
    },
    showEnvSection: (state, getters) => {
      return audienceService.showEnvSection(getters.envsTaxonomy)
    },

    // SCHEDULE
    getFullStartDate: state => {
      return moment(state.startDate + ' ' + state.startTime, DatetimeFormattingEnum.DATETIME).format()
    },
    getStartDate: state => {
      return state.startDate
    },
    getStartTime: state => {
      return state.startTime
    },
    getFullEndDate: state => {
      return moment(state.endDate + ' ' + state.endTime, DatetimeFormattingEnum.DATETIME_ROUNDED_HOUR).format()
    },
    getEndDate: state => {
      return state.endDate
    },
    getEndTime: state => {
      return state.endTime
    },

    getMapBounds: state => {
      return state.mapOptions
    },
    getInitialBounds: state => {
      return state.initialMapOptions
    },
    filterAsImove: state => {
      return state.filterAsImoveMap
    },
    getPagination: state => {
      return state.pagination
    },
    getFocusedGeoInfo: state => {
      return state.focusedGeoInfo
    },
    getUsePublicExchange: state => {
      return state.usePublicExchange
    },
    getCurrency: state => {
      return state.currency
    },
    getMapViewPort: state => {
      return state.viewPort
    }
  },

  mutations: {
    // controls open/close status only
    updateTargetStateController (state, data) {
      var target = state.targetStateController.find(target => target.key === data.targetKey)
      if (target) {
        // when closing a Mobile Target's widget, clear any values for this target
        target.value = data.value
      } else {
        state.targetStateController.push({
          key: data.targetKey,
          value: data.value
        })
      }
    },

    resetAudience (state) {
      Object.assign(state, audienceStateFactory())
    },

    updateGeoTargets (state, data) {
      state.geoTargets = data
    },

    updateScreenSizeAndOrientation (state, data) {
      var { field, values } = data
      state[field] = values
    },

    updateEnvironments (state, segmentsObj) {
      state.segments = segmentsObj
    },

    updateForecastVenues (state, forecastVenues) {
      state.forecastVenues = Object.assign({}, state.forecastVenues, forecastVenues)
    },
    updateForecastVenuesLoading (state, forecastVenuesLoading) {
      state.forecastVenuesLoading = forecastVenuesLoading
    },

    updateForecastInventory (state, forecast) {
      state.forecastInventory = Object.assign({}, state.forecastInventory, forecast)
    },
    updateForecastInventoryLoading (state, forecastLoading) {
      state.forecastInventoryLoading = forecastLoading
    },

    // New! merged targeted & budgeted Forecast
    updateForecast (state, forecast) {
      state.forecast = Object.assign({}, state.forecast, forecast)
    },
    updateForecastLoading (state, forecastLoading) {
      state.forecastLoading = forecastLoading
    },

    // LOAD functions

    setSegments (state, segments) {
      state.segments = segments
    },

    setGeography (state, geography) {
      state.geography = geography
    },

    setTargeting (state, targets) {
      state.targeting = targets
    },

    updateTargetingTarget (state, target) {
      if (target.targetValues.length) {
        var existingTarget = state.targeting.find(st => st.target === target.target)
        if (existingTarget) {
          existingTarget.targetValues = target.targetValues
        } else {
          if (!audienceService.NON_DEMOGRAPHIC_TARGETS.includes(target.target)) {
            tracking.sendEvent(['ga'], 'checkedDemographic', { label: target.target })
          }
          state.targeting.push(target)
        }
      } else {
        // no "targetValues", remove target
        var existingIndex = state.targeting.findIndex(st => st.target === target.target)
        if (existingIndex > -1) {
          if (!audienceService.NON_DEMOGRAPHIC_TARGETS.includes(state.targeting[existingIndex].target)) {
            tracking.sendEvent(['ga'], 'uncheckedDemographic', { label: state.targeting[existingIndex].target })
          }
          state.targeting.splice(existingIndex, 1)
        }
      }
    },

    updateDaypart (state, daypart) {
      var existingDaypartTargetIndex = state.targeting.findIndex(t => t.target === 'daypart')
      if (existingDaypartTargetIndex > -1) {
        // target exists
        if (!daypart.length || daypart.length === 24) {
          // remove it if none/all values selected
          state.targeting.splice(existingDaypartTargetIndex, 1)
        } else {
          // update it
          state.targeting[existingDaypartTargetIndex].targetValues = daypart
        }
      } else {
        // create it if some BUT NOT all values selected
        if (daypart.length && daypart.length < 24) {
          state.targeting.push({
            target: 'daypart',
            targetValues: daypart
          })
        }
      }
    },

    updateWeekpart (state, weekpart) {
      var existingWeekpartTargetIndex = state.targeting.findIndex(t => t.target === 'weekpart')
      if (existingWeekpartTargetIndex > -1) {
        // target exists
        if (!weekpart.length || weekpart.length === 7) {
          // remove it if none/all values selected
          state.targeting.splice(existingWeekpartTargetIndex, 1)
        } else {
          // update it
          state.targeting[existingWeekpartTargetIndex].targetValues = weekpart
        }
      } else {
        // create it if some BUT NOT all values selected
        if (weekpart.length && weekpart.length < 7) {
          state.targeting.push({
            target: 'weekpart',
            targetValues: weekpart
          })
        }
      }
    },

    // Schedule related
    setStartDate (state, startDate) {
      state.startDate = startDate
    },
    setStartTime (state, startTime) {
      state.startTime = startTime
    },
    setEndDate (state, endDate) {
      state.endDate = endDate
    },
    setEndTime (state, endTime) {
      state.endTime = endTime
    },

    setMapBounds (state, obj) {
      if (!state.initialMapOptions) state.initialMapOptions = obj
      Vue.set(state, 'mapOptions', obj)
    },
    setFilterAsImove (state, val) {
      state.filterAsImoveMap = val
    },
    setPagination (state, val) {
      const page = val.page || state.pagination.page
      const take = val.take || state.pagination.take
      val.skip = (page - 1) * take
      state.pagination = Object.assign({}, state.pagination, val)
    },
    setFocusedGeoInfo (state, val) {
      state.focusedGeoInfo = val
    },
    setMapViewPort (state, val) {
      state.viewPort = {
        minLatitude: val.min.latitude,
        minLongitude: val.min.longitude,
        maxLatitude: val.max.latitude,
        maxLongitude: val.max.longitude,
        zoom: val.zoom
      }
    },
    saveInitialTargeting (state, val) {
      state.initialTargeting = [...val]
    },
    setUsePublicExchange (state, bool) {
      state.usePublicExchange = bool
    },
    setCurrency (state, currency) {
      state.currency = currency
    }
  },
  actions: {
    async loadAudience (context, audience) {
      // context.commit('resetAudience')

      if (audience.audienceSegments && audience.audienceSegments.length) {
        context.commit('setTargeting', JSON.parse(JSON.stringify(audience.audienceSegments)))
      }

      if (audience.targeting && audience.targeting.length) {
        context.commit('setTargeting', JSON.parse(JSON.stringify(audience.targeting)))
      }

      if (audience.geography) {
        const geography = JSON.parse(JSON.stringify(audience.geography))
        context.commit('setGeography', geography)
      }

      if (audience.segments) {
        context.commit('setSegments', audience.segments)
      }

      if (audience.startDate) {
        const startDateMoment = moment(audience.startDate, DatetimeFormattingEnum.DATETIME)
        context.commit('setStartDate', startDateMoment.format(DatetimeFormattingEnum.DATE_ONLY))
        context.commit('setStartTime', startDateMoment.format(DatetimeFormattingEnum.TIME_ONLY))
      }

      if (audience.endDate) {
        const endDateMoment = moment(audience.endDate, DatetimeFormattingEnum.DATETIME_ROUNDED_HOUR)
        context.commit('setEndDate', endDateMoment.format(DatetimeFormattingEnum.DATE_ONLY))
        context.commit('setEndTime', endDateMoment.format(DatetimeFormattingEnum.ROUNDED_HOUR))
      }
    },

    addDigitalFaceTypeTarget (context) {
      const digitalFaceTypeTarget = {
        target: 'facetype',
        targetValues: ['digital']
      }
      context.commit('updateTargetingTarget', digitalFaceTypeTarget)
    },

    addAudience (context, audience) {
      return audienceAPI.addAudience(audience)
    },

    editAudience (context, audience) {
      return audienceAPI.editAudience(audience)
    },

    // Temporarily here
    editLine (context, audience) {
      return reportingAPI.editLine(audience)
    },

    updateGeoTargetsAction (context, data) {
      var newGeography = geoService.geoTargetsToApiFormatting(data)
      context.commit('setGeography', newGeography)
    },

    updateVenueInclusion ({ getters, dispatch }, venue) {
      const selectedGeoTargets = JSON.parse(JSON.stringify(getters.selectedGeoTargets))
      const existingGeoTarget = selectedGeoTargets.find(g => g.value === venue.id)
      if (existingGeoTarget) {
        // do we need to .splice() ..? cause we don't know wether :
        // we're re-INcluding a Venue that was already INcluded
        // -or-
        // if we're re-EXcluding a Venue that was already EXcluded
        existingGeoTarget.isIncluded = !existingGeoTarget.isIncluded
      } else {
        const geoTarget = {
          isIncluded: !venue.isIncluded,
          radius: 'Venue Only',
          type: 'venue',
          label: venue.name,
          value: venue.id.toString(),
          geography: {
            latitude: venue.latitude,
            longitude: venue.longitude
          }
        }
        selectedGeoTargets.push(geoTarget)
      }
      dispatch('updateGeoTargetsAction', selectedGeoTargets)
    },

    updateMobileTargetsAction (context, data) {
      var selectedTargets = [...context.getters.selectedTargets]
      if (['Age-range', 'Income-range'].includes(data.field)) {
        var target = {
          target: data.field.toLowerCase(),
          targetValues: data.values
        }
        context.commit('updateTargetingTarget', target)
      } else {
        if (data.values.length) {
          var newMobileTargets = audienceService.mobileTargetsToTargetTargetValuesFormatting(data.values)
          newMobileTargets.map(mobileTarget => {
            context.commit('updateTargetingTarget', mobileTarget)
          })

          // Remove unchecked ones
          const categoryTargets = audienceService.extractChildren(context.getters.activeMobileTargetGroups.find(targetGroup => targetGroup.key === data.field))
          selectedTargets.map(st => {
            if (categoryTargets.includes(st.target) && !newMobileTargets.map(x => x.target).includes(st.target)) {
              context.commit('updateTargetingTarget', {
                target: st.target,
                targetValues: []
              })
            }
          })
        } else {
          var targetGroupRemoved = context.getters.activeMobileTargetGroups.find(targetGroup => targetGroup.key === data.field)
          const children = audienceService.extractChildren(targetGroupRemoved)

          selectedTargets.map(st => {
            if (children.includes(st.target)) {
              context.commit('updateTargetingTarget', {
                target: st.target,
                targetValues: []
              })
            }
          })
        }
      }
    },

    updateScreenTargetsAction (context, data) {
      if (data.field === 'screenSize') {
        context.commit('updateTargetingTarget', {
          target: 'screensizerange',
          targetValues: data.values
        })
      }
    },

    updateForecastVenues (context, data) {
      context.commit('updateForecastVenuesLoading', true)

      const pagination = context.getters.getPagination
      const mapBounds = pagination.filtering || !context.getters.filterAsImove
        ? context.getters.getInitialBounds
        : context.getters.getMapBounds
      const options = Object.assign({}, data, { map: mapBounds })

      audienceAPI.getForecastVenues(options, pagination)
        .then(res => {
          // request might have been canceled by subsequent one
          if (res) {
            const forecast = res.data

            context.commit('setPagination', {
              hasMorePages: JSON.parse(res.headers['x-has-more-rows'])
            })

            context.commit('updateForecastVenues', forecast)
            context.commit('updateForecastVenuesLoading', false)
          }
        })
    },

    updateForecastInventory (context, data) {
      context.commit('updateForecastInventoryLoading', true)

      const pagination = context.getters.getPagination
      const mapBounds = pagination.filtering
        ? context.getters.getInitialBounds
        : context.getters.getMapBounds
      const options = Object.assign({}, data, { map: mapBounds })

      audienceAPI.getForecastInventory(options, pagination)
        .then(res => {
          // request might have been canceled by subsequent one
          if (res && res.data) {
            context.commit('updateForecastInventory', res.data)
            context.commit('updateForecastInventoryLoading', false)
          }
        })
    },

    updateForecast (context, data) {
      context.commit('updateForecastLoading', true)

      audienceAPI.getForecast(data)
        .then(res => {
          // request might have been canceled by subsequent one
          if (res) {
            const forecast = res.data

            // budget object is not returned (null) instead of being returned empty {}
            // which will overwrite our initial State
            if (!forecast.budget) {
              delete forecast.budget
            }

            forecast.bidRanges = audienceService.GetMinMaxForecastBidRange(data, forecast)

            context.commit('updateForecast', forecast)
            context.commit('updateForecastLoading', false)
          }
        })
    }
  }
}
