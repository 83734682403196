<template lang="pug">
  v-expansion-panel#inventory-market-panel
    v-expansion-panel-header.text-h6 Inventory Market
    v-expansion-panel-content(eager)
      v-radio-group.mt-0(:value='market' hide-details :disabled='disabled || !market' @change='updateMarket')
        v-radio(
          v-for='exchangeValues in orderedExchangeValues'
          :key="exchangeValues.id"
          :label='exchangeValues.label'
          :value='exchangeValues.market'
          color='primary'
        )
</template>

<script>
import defaultExchangeValues from '@/services/defaultExchangeValues'
import inventoryMarketCache from '@/services/inventoryMarketCache'

export default {
  props: {
    disabled: {
      type: Boolean,
      default: () => false
    },
    market: {
      type: String,
      validator (market) {
        return defaultExchangeValues.getAllDefaultValues().some(ev => ev.market === market)
      }
    }
  },
  computed: {
    orderedExchangeValues () {
      const LAST_MARKET = 'global'

      return defaultExchangeValues
        .getAllDefaultValues()
        .sort((a, b) => {
          if (a.market === LAST_MARKET) return 1
          if (b.market === LAST_MARKET) return -1
          return a.label.localeCompare(b.label)
        })
        .map(exchangeValues => {
          return {
            id: exchangeValues.id,
            label: exchangeValues.label.replace(/\s?\(.*\)$/, '').trim(),
            market: exchangeValues.market
          }
        })
    }
  },
  methods: {
    updateMarket (market) {
      this.resetGeoTargets()
      this.updateStoreExchange(market)
      inventoryMarketCache.saveMarket(market)
    },
    resetGeoTargets () {
      this.$store.dispatch('audience/updateGeoTargetsAction', [])
    },
    updateStoreExchange (market) {
      this.$store.commit('createCampaign/storeExchangeDetails', {
        exchange:
          {
            key: market,
            id: this.orderedExchangeValues.find(m => m.market === market).id
          }
      })
    }
  }
}
</script>
