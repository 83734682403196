import helpers from '@/services/helpers.service'
import audienceApi from '@/services/audience.api'
import userApi from '@/services/user.api'
import campaignsApi from '@/services/campaigns.api'
import defaultExchangeValues from '@/services/defaultExchangeValues'
import localizationService from '@/services/localization'
import flags from '@/plugins/rox/flags'

export default {
  namespaced: true,
  state: {
    initializing: false,
    exchanges: [],
    taxonomies: {},
    targets: {},
    targetGroups: {},
    advertisers: [],
    industries: [],
    localText: {
      targetDescriptions: null
    },
    isThemeLoaded: false,
    organizationLogo: null,
    organizationTheme: null
  },
  getters: {
    appInitializing: state => {
      return state.initializing
    },

    appReady4Real: state => {
      return state.exchanges.length &&
      state.industries.length
    },

    getAdvertisers: state => {
      return state.advertisers
    },
    getIndustries: state => {
      return state.industries
    },
    getIndustry: state => industryId => {
      return state.industries.filter(industry => industry.id).find(industry => industry.id === industryId)
    },
    getExchanges: state => {
      return state.exchanges
    },

    allTargetGroups: state => {
      return state.targetGroups
    },
    allTargets: state => {
      return state.targets
    },
    allTaxonomies: state => {
      return state.taxonomies
    },
    marketDefaultValues: (state, getters, rootState, rootGetters) => {
      const org = rootGetters['user/getOrganization']
      return defaultExchangeValues.getDefaultValuesByCurrency(org.currency ? org.currency.code : 'USD')
    },
    isOnAuctionPackagePage: (state, getters, rootState, rootGetters) => {
      return Boolean(window.location.pathname.includes('/auction-packages') && (rootGetters['general/isAuctionPackageVisible'] || !rootGetters['auth/isLoggedIn']))
    },
    marketValueFromExchange: (state, getters, rootState, rootGetters) => exchange => {
      return defaultExchangeValues.getAllDefaultValues().find(x => x.market === exchange)
    },
    currentPageExchange: (state, getters, rootState, rootGetters) => {
      return rootGetters['general/isOnAuctionPackagePage'] ? rootGetters['auctionPackage/getExchange'] : rootGetters['createCampaign/getExchange']
    },
    getLocalText: state => section => {
      return state.localText[section]
    },
    getIsThemeLoaded: state => {
      return state.isThemeLoaded
    },
    getOrganizationLogo: state => {
      return state.organizationLogo ? URL.createObjectURL(state.organizationLogo) : null
    },
    fullTheme: state => {
      return state.organizationTheme
        ? { ...state.organizationTheme, logo: state.organizationLogo }
        : { logo: state.organizationLogo }
    },
    isTestEnv: () => {
      return !['https://buy.broadsign.com', 'https://campaigns.buy.broadsign.com'].includes(window.location.origin)
    },
    troubleshootingToolVisible: (state, getters, rootState, rootGetters) => {
      return rootGetters['user/isGlobalAdmin']
    },
    isAuctionPackageVisible: (state, getters, rootState, rootGetters) => {
      return rootGetters['user/isGlobalAdmin'] && flags.canSeeAuctionPackage.isEnabled()
    },
    isAuctionPackageMenuVisible: (state, getters, rootState, rootGetters) => {
      return rootGetters['user/isGlobalAdmin'] && flags.canSeeAuctionPackageMenu.isEnabled()
    },
    isDspPartnerDashboardVisible: (state, getters, rootState, rootGetters) => {
      return rootGetters['user/isGlobalAdmin']
    }
  },
  mutations: {
    storeExchanges: (state, list) => {
      state.exchanges = list
    },
    storeAdvertisers: (state, list) => {
      state.advertisers = list
    },
    addAdvertiser: (state, advertiser) => {
      state.advertisers = helpers.appendItemToOrderedList(advertiser, [...state.advertisers], 'name')
    },
    storeIndustries: (state, list) => {
      state.industries = list
    },
    storeTaxonomy: (state, obj) => {
      state.taxonomies[obj.key] = obj.val
    },
    storeTargets: (state, obj) => {
      state.targets[obj.key] = obj.val
    },
    storeTargetGroups: (state, obj) => {
      state.targetGroups[obj.key] = obj.val
    },
    setInitializing: (state, val) => {
      state.initializing = val
    },
    resetApp: (state) => {
      state.initializing = false
      state.exchanges = []
      state.taxonomies = {}
      state.targets = {}
      state.targetGroups = {}
      state.advertisers = []
      state.industries = []
    },
    setLocalText: (state, obj) => {
      state.localText[obj.section] = obj.value
    },
    setIsThemeLoaded: (state, bool) => {
      state.isThemeLoaded = bool
    },
    setOrganizationLogo: (state, res) => {
      state.organizationLogo = res
    },
    setOrganizationTheme: (state, res) => {
      state.organizationTheme = res
    },
    clearTheme: (state) => {
      state.organizationTheme = null
      state.organizationLogo = null
      state.isThemeLoaded = false
    }
  },
  actions: {
    getExchanges ({ commit, dispatch }) {
      return audienceApi.getExchanges()
        .then(res => {
          // fake only 1 exchange now Canada
          var exchanges = res.filter(exchange => exchange.key !== 'outdoor')
          commit('storeExchanges', exchanges)
          commit('setInitializing', false)
          return exchanges
        })
        .catch(() => {
          // Signals that it is done initializing
          commit('setInitializing', false)

          // "shared token" for a Proposal will expire when converted to Campaign and will return a 401 error
          // return empty Exchanges, so normal flow continues until Proposal store's fetch() fails and handles 401
          return []
        })
    },
    getTaxonomy ({ commit }, exchange) {
      return audienceApi.getEnvsTaxonomy(exchange.id).then(res => {
        commit('storeTaxonomy', { key: exchange.key, val: res })
      })
    },
    getTargets ({ commit }, exchange) {
      return audienceApi.getTargets(exchange.id).then(res => {
        commit('storeTargets', { key: exchange.key, val: res })
      })
    },
    getTargetGroups ({ commit }, exchange) {
      return audienceApi.getTargetsGroups(exchange.id).then(res => {
        var notEmptyList = res.filter(r => r.groups.length || r.targets.length)
        restructureTree(notEmptyList)
        commit('storeTargetGroups', { key: exchange.key, val: notEmptyList })
      })
      /**
       * Restructures (in place) the tree object to have only groups, instead of groups and targets.
       * Necessary for Vuetify Treeview component to work properly
       */
      function restructureTree (targetsGroups) {
        targetsGroups.map(targetsGroup => {
          if (targetsGroup.groups) {
            if (targetsGroup.targets.length) {
              targetsGroup.groups.push(...targetsGroup.targets)
            }

            if (targetsGroup.groups.length) {
              restructureTree(targetsGroup.groups)
            }
          }
        })
      }
    },
    getExchangesTargeting ({ state, dispatch }) {
      const promises = []
      if (!Object.keys(state.targetGroups).length) {
        for (const exchange of state.exchanges) {
          promises.push(dispatch('getTargetGroups', exchange))
        }
      }
      if (!Object.keys(state.targets).length) {
        for (const exchange of state.exchanges) {
          promises.push(dispatch('getTargets', exchange))
        }
      }
      if (!Object.keys(state.taxonomies).length) {
        for (const exchange of state.exchanges) {
          promises.push(dispatch('getTaxonomy', exchange))
        }
      }
      return Promise.all(promises)
    },
    getAdvertisers ({ commit }, organizationId) {
      campaignsApi.getAdvertisers(organizationId).then(res => {
        const alphabeticallySortedList = res.data.sort((item1, item2) => {
          if (item1.name < item2.name) { return -1 }
          if (item1.name > item2.name) { return 1 }
          return 0
        })
        commit('storeAdvertisers', alphabeticallySortedList)
      })
    },
    getIndustries ({ commit }) {
      return campaignsApi.getIndustries().then(res => {
        const alphabeticallySortedList = res.data.sort((item1, item2) => {
          if (item1.name < item2.name) { return -1 }
          if (item1.name > item2.name) { return 1 }
          return 0
        })
        const formattedList = []
        alphabeticallySortedList.forEach(group => {
          formattedList.push({ header: group.name })
          const sortedSubs = group.subIndustries.sort((item1, item2) => {
            if (item1.name < item2.name) { return -1 }
            if (item1.name > item2.name) { return 1 }
            return 0
          })
          formattedList.push(...sortedSubs)
          formattedList.push({ divider: true })
        })
        commit('storeIndustries', formattedList)
      })
    },
    async initializeApp ({ dispatch, getters, commit }) {
      // if not already in the process of initializing
      if (!getters.appInitializing && !getters.appReady4Real) {
        commit('setInitializing', true)

        await dispatch('getIndustries')
      }
    },
    fetchLocalText ({ commit, state }, section) {
      if (!state.localText[section]) {
        localizationService.fetchContent(section).then(res => {
          commit('setLocalText', { section, value: res })
        })
      }
    },
    async fetchTheme ({ commit, rootGetters }, obj) {
      const logoRes = await userApi.getLogo(obj.orgId, rootGetters['user/getToken'], obj.scopedToken)
      const themeRes = await userApi.getTheme(obj.orgId)
      if (!obj.isPreviewOnly) {
        if (themeRes) commit('setOrganizationTheme', themeRes)
        if (logoRes) commit('setOrganizationLogo', logoRes)
        commit('setIsThemeLoaded', true)
        return themeRes
      }
      return themeRes
        ? { ...themeRes, logo: logoRes }
        : { logo: logoRes }
    },
    deleteTheme ({ state, commit }, obj) {
      return Promise.all([userApi.deleteTheme(obj.orgId), userApi.deleteLogo(obj.orgId)]).then(() => {
        if (!obj.isPreviewOnly) {
          commit('setOrganizationTheme', { ...state.organizationTheme, PrimaryColour: null })
          commit('setOrganizationLogo', null)
        }
      })
    },
    async saveTheme ({ commit }, data) {
      if (data.theme) {
        await userApi.saveTheme(data.orgId, data.theme)
        if (!data.isPreviewOnly) commit('setOrganizationTheme', data.theme)
      }
      if (data.logo) {
        await userApi.uploadLogo(data.orgId, data.logo)
        if (!data.isPreviewOnly) commit('setOrganizationLogo', data.logo)
      }
    }
  }
}
