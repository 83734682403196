<template lang='pug'>
  div(v-if='gotOrganization')
    v-dialog(v-model='isDialogOpen' :fullscreen='$vuetify.breakpoint.smAndDown' max-width='700')
      template(v-if="!noActivator" v-slot:activator='{on}')
        v-btn#load-package-btn.px-2(text left color='primary' @click.stop='openDialog') Load {{ packageLabel | capitalize }}

      v-card(flat style='min-height:600px;')
        v-toolbar(flat color='grey lighten-4')
          //-v-toolbar-title.text-h5.hidden-sm-and-down Load Audience
          v-text-field(
            v-model='search'
            id= 'load-audience-search-field'
            prepend-inner-icon='mdi-magnify'
            solo
            dense
            clearable
            hide-details
            :label='"Find " + (canSeeNewUi ? "Package" : "Audience")'
          )
          v-btn#load-package-close-btn(icon @click='closeDialog')
            v-icon(color='grey') mdi-close
        v-data-table(
          :headers='headers'
          :items='shownAudiences'
          :server-items-length='totalItems'
          :loading='isLoading'
          :options.sync='pagination'
          :footer-props="{itemsPerPageOptions: rowsPerPageItems}"
          item-key='id'
          :no-data-text='noDataText'
          no-results-text=''
        )
          template(v-slot:item.name='{ item }')
            a.info--text(style='text-decoration: none;' @click='pickedAudience(item)')
              span#load-audience-name {{ item.name }}
          template(v-slot:item.reachAvailability='{ item }')
            span#load-audience-reach {{ numberFormatting(item.reachAvailability) }}
          template(v-slot:item.numberOfVenues='{ item }')
            v-icon(color='primary' small) mdi-map-marker
            span#load-audience-num-venues {{ numberFormatting(item.numberOfVenues) }}
          template(v-slot:item.updatedOn='{ item }')
            span#load-audience-updatedOn {{ item.updatedOn | date('MMMM Do YYYY') }}
</template>

<script>
import audienceApi from '@/services/audience.api'
import audienceService from '@/services/audience.service'
import _ from 'lodash'

export default {
  props: ['noActivator', 'openFromParent'],
  created: function () {
    this.canSeeNewUi = this.$flags.canSeeUiRebrand.isEnabled()
    if (this.canSeeNewUi) {
      this.packageLabel = 'package'
      this.headers = this.headers.map(obj =>
        obj.value === 'name' ? { ...obj, text: 'Package' } : obj
      )
    }
  },
  watch: {
    search: _.debounce(function () {
      this.getAudiences()
    }, 450),
    pagination: function (newVal, oldVal) {
      this.getAudiences()
    },
    openFromParent: function (newVal) {
      if (newVal) { this.isDialogOpen = true }
    },
    isDialogOpen: function (newVal, oldVal) {
      if (!newVal) {
        this.search = ''
        this.$emit('closedDialog')
      }
    }
  },
  computed: {
    gotOrganization () {
      return this.$store.getters['user/getOrganization']
    },

    noDataText () {
      if (!this.shownAudiences.length && this.search.length) {
        return 'No ' + this.packageLabel + 's found'
      } else {
        return ''
      }
    }
  },
  data () {
    return {
      audiencesFull: [],
      shownAudiences: [],
      isLoading: true,
      search: '',
      lastSearch: '',
      isDialogOpen: false,
      headers: [
        { text: 'Audience', align: 'left', value: 'name', width: '250px' },
        { text: 'Impressions', align: 'left', value: 'reachAvailability' },
        { text: 'Venues', align: 'left', value: 'numberOfVenues' },
        { text: 'Last Updated', align: 'left', value: 'updatedOn' }
      ],
      totalItems: 0,
      rowsPerPageItems: [10, 25, 50],
      pagination: {
        sortBy: ['updatedOn'],
        sortDesc: [true],
        itemsPerPage: 10,
        page: 1
      },
      canSeeNewUi: false,
      packageLabel: 'audience'
    }
  },
  methods: {
    getAudiences () {
      this.isLoading = true

      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination

      const skip = (page - 1) * itemsPerPage
      const take = itemsPerPage
      const sort = sortDesc[0] ? 'desc' : 'asc'

      // Filters
      var filters = []

      // filter per (selected) Exchange
      const exchangeId = this.$store.getters['createCampaign/getExchange'].id
      if (exchangeId) {
        filters.push({ name: 'exchangeId', value: exchangeId })
      }

      if (this.search) {
        filters.push({ name: 'search', value: this.search })
      }

      // get total count only if NEW search
      if (!this.totalItems || this.lastSearch !== this.search) {
        this.lastSearch = this.search

        audienceApi.getAudiencesCount(filters)
          .then(count => {
            this.totalItems = count
          })
      }

      audienceApi.getAudiences(skip, take, sortBy[0], sort, filters)
        .then(audiences => {
          this.shownAudiences = audiences
          this.audiencesFull = audiences
          this.isLoading = false
        })
    },
    openDialog () {
      this.isDialogOpen = true
    },
    closeDialog () {
      this.isDialogOpen = false
      this.search = ''
    },
    numberFormatting (nbr) {
      if (nbr) {
        return audienceService.numberWithCommasFormatting(nbr)
      } else {
        return '0'
      }
    },
    pickedAudience (audience) {
      this.isDialogOpen = false
      this.search = ''
      this.$emit('pickedAudience', audience)
    }
  }
}
</script>
