import axios from 'axios'
import helpers from '@/services/helpers.service'
import audienceService from '@/services/audience.service'

// https://stackoverflow.com/questions/51269431/jest-mock-inner-function/55193363#55193363
import audienceApi from './audience.api'

var { VUE_APP_CAMPSITE_API_URL } = process.env

export default {
  getExchanges,
  getAudiences,
  getAudiencesCount,
  getAudience,
  getForecastVenues,
  getForecastInventory,
  getForecast,
  getTargets,
  getTargetsGroups,
  getEnvsTaxonomy,
  addAudience,
  editAudience,
  getListTargetedInventory,
  getForecastInventoryCsv,
  getDmpDistribution,
  getDmpHistorical,
  processAudience,
  getDeal,
  getDeals,
  getDealsCount,
  editDeal,
  getDealsAsCSV,
  cancelForecastRequests
}

function getExchanges () {
  return axios.get(VUE_APP_CAMPSITE_API_URL + '/exchanges').then(response => response.data)
}

async function getListTargetedInventory (forecastOptions, scope = 'venues') {
  var numRows = 0
  var allResults = ''

  // 'take' is limited to 1,000 and we allow max 3,000 results => 3 x 1,000
  const take = 1000

  for (var batch = 0; batch < 3; batch++) {
    var skip = batch * take
    var results = await audienceApi.getForecastInventoryCsv(forecastOptions, skip, take, scope)

    const rows = results.split('\n')

    // keep Header row on first batch only
    if (batch === 0) {
      numRows = rows.length - 1
      allResults += results
    } else {
      rows.shift()
      numRows = rows.length
      allResults += '\n' + rows.join('\n')
    }

    if (numRows < take) {
      break
    }
  }

  return allResults
}

function getForecastInventoryCsv (forecastOptions, skip = 0, take = 1000, scope) {
  const config = {
    Accept: 'text/csv',
    'Content-Type': 'application/json'
  }

  const url = VUE_APP_CAMPSITE_API_URL + '/forecasts/' + scope + '?take=' + take + '&skip=' + skip
  return axios({ method: 'post', url: url, data: forecastOptions, headers: config })
    .then(response => {
      return response.data
    })
    .catch(err => {
      console.log(err)
      return ''
    })
}

function getAudiencesCount (filters = []) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/audiences'

  var filtersString = buildfiltersString(filters)
  if (filtersString) {
    endpoint += '?' + filtersString
  }

  return axios.head(endpoint).then(response => parseInt(response.headers['x-count']) || 0)
}

function getAudiences (skip = 0, take = 5, orderBy = 'id', order = 'desc', filters = [], excludeGeojson = false) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/audiences?take=' + take + '&skip=' + skip + '&$orderby=' + orderBy + ' ' + order

  var filtersString = buildfiltersString(filters)
  if (filtersString) {
    endpoint += '&' + filtersString
  }
  if (excludeGeojson) endpoint += '&removeGeoJson=true'
  return axios.get(endpoint).then(response => response.data)
}

function getAudience (audienceId) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/audiences/' + audienceId
  return axios.get(endpoint)
}

// FORECAST

var forecastVenuesRequest = {}
function getForecastVenues (forecastOptions, paginationOptions = null) {
  var { mapId } = forecastOptions

  if (forecastVenuesRequest[mapId]) {
    forecastVenuesRequest[mapId].cancel('Previous forecast VENUES request canceled.')
  }
  forecastVenuesRequest[mapId] = axios.CancelToken.source()

  var baseUrl = VUE_APP_CAMPSITE_API_URL + '/forecasts/venues'
  const params = paginationOptions
    ? audienceService.venuesQueryStringBuilder(paginationOptions)
    : []

  if (params.length) {
    baseUrl += '?' + params.join('&')
  }

  return axios.post(baseUrl, forecastOptions, { cancelToken: forecastVenuesRequest[mapId].token })
    .then(response => response)
    .catch(err => {
      if (!axios.isCancel(err)) {
        console.error({ err: err.toString(), baseUrl, data: forecastOptions })
      }
    })
}

var forecastInventoryRequests = {}
function getForecastInventory (data, paginationOptions = null) {
  var { mapId } = data

  if (forecastInventoryRequests[mapId]) {
    forecastInventoryRequests[mapId].cancel('Previous forecast INVENTORY request canceled.')
  }
  forecastInventoryRequests[mapId] = axios.CancelToken.source()

  var baseUrl = VUE_APP_CAMPSITE_API_URL + '/forecasts/inventory'
  const params = paginationOptions
    ? audienceService.venuesQueryStringBuilder(paginationOptions)
    : []

  if (params.length) {
    baseUrl += '?' + params.join('&')
  }

  return axios.post(baseUrl, data, { cancelToken: forecastInventoryRequests[mapId].token })
    .then(response => response)
    .catch(err => {
      if (!axios.isCancel(err)) {
        console.error({ err: err.toString(), baseUrl, data })
      }
    })
}

var forecastRequests = {}
function getForecast (data) {
  var { mapId } = data

  if (forecastRequests[mapId]) {
    forecastRequests[mapId].cancel('Previous forecast request canceled.')
  }
  forecastRequests[mapId] = axios.CancelToken.source()

  const baseUrl = VUE_APP_CAMPSITE_API_URL + '/forecasts'
  return axios.post(baseUrl, data, { cancelToken: forecastRequests[mapId].token })
    .then(response => response)
    .catch(err => {
      if (!axios.isCancel(err)) {
        console.error({ err: err.toString(), baseUrl, data })
      }
    })
}

function cancelForecastRequests (mapId) {
  if (forecastRequests[mapId]) forecastRequests[mapId].cancel('Request canceled.')

  if (forecastVenuesRequest[mapId]) forecastVenuesRequest[mapId].cancel('Request canceled.')

  if (forecastInventoryRequests[mapId]) forecastInventoryRequests[mapId].cancel('Request canceled.')
}

function getTargets (exchangeId) {
  // const exposedtargets = ['creativeformat', 'screensizerange']
  return axios.get(VUE_APP_CAMPSITE_API_URL + '/exchanges/' + exchangeId + '/targets')
    // .then(res => res.data.filter(target => exposedtargets.indexOf(target.value) > -1))
    .then(res => res.data)
}

function getTargetsGroups (exchangeId) {
  return axios.get(VUE_APP_CAMPSITE_API_URL + '/exchanges/' + exchangeId + '/targetgroups')
    .then(res => {
      return res.data
        .map(group => {
          // generate better keys ;)
          group.key = group.key.replace(/[&\s]/g, '')
          return group
        })
    })
}

function getEnvsTaxonomy (exchangeId) {
  return axios.get(VUE_APP_CAMPSITE_API_URL + '/exchange/' + exchangeId + '/taxonomies').then(res => {
    return (res.data && res.data.length) ? res.data[0].taxonomy : []
  })
}

function addAudience (audience) {
  return axios.post(VUE_APP_CAMPSITE_API_URL + '/audiences', audience)
}

function editAudience (audience) {
  return axios.put(VUE_APP_CAMPSITE_API_URL + '/audiences/' + audience.id, audience)
}

function getDmpDistribution (params) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/reports/dmp?type=characteristic'
  endpoint += '&compareFrom=' + params.compareFrom
  endpoint += '&compareTo=' + params.compareTo
  endpoint += '&from=' + params.startDate
  endpoint += '&to=' + params.endDate

  return axios.get(endpoint).then(res => res.data)
}

function getDmpHistorical (params) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/reports/historical?type=characteristic'
  endpoint += '&compareFrom=' + params.compareFrom
  endpoint += '&compareTo=' + params.compareTo

  return axios.get(endpoint).then(res => res.data)
}

function processAudience (audienceId) {
  return axios.post(VUE_APP_CAMPSITE_API_URL + '/audiences/' + audienceId + '/process').then(res => res)
}

function getDeal (id, organizationId = null) {
  if (organizationId) {
    return axios.get(VUE_APP_CAMPSITE_API_URL + '/deals/' + id + '?organizationId=' + organizationId).then(res => res.data)
  }
  return axios.get(VUE_APP_CAMPSITE_API_URL + '/deals/' + id).then(res => res.data)
}

function getDealsCount (pagination, parameters) {
  const endpoint = buildDealsEndpoint(pagination, parameters.search, parameters.status, parameters.organizationId, true)
  return axios.head(endpoint).then(response => parseInt(response.headers['x-count']) || 0)
}

function getDeals (pagination, parameters) {
  const endpoint = buildDealsEndpoint(pagination, parameters.search, parameters.status, parameters.organizationId)
  return axios.get(endpoint).then(res => res.data)
}

function editDeal (id, modifiedFields) {
  const endpoint = `${VUE_APP_CAMPSITE_API_URL}/deals/${id}`
  return axios.patch(endpoint, modifiedFields).then(res => res.data)
}

function getDealsAsCSV (pagination, search = null, status = null) {
  const config = {
    Accept: 'text/csv',
    'Content-Type': 'application/json'
  }
  const endpoint = buildDealsEndpoint(pagination, search, status)
  return axios({ method: 'get', url: endpoint, headers: config }).then(response => response.data)
}

function buildDealsEndpoint (pagination, search = null, status = null, organizationId = null, selectAll = false) {
  const list = []

  if (pagination) {
    const skip = (pagination.page - 1) * pagination.itemsPerPage
    const orderBy = pagination.sortBy[0]
    const order = pagination.sortDesc[0] ? 'desc' : 'asc'
    if (!selectAll) list.push(`skip=${skip}`, `take=${pagination.itemsPerPage}`)
    list.push(`$orderby=${orderBy} ${order}`)
  }

  const filters = []
  if (search) filters.push({ name: 'search', value: search })
  if (status) filters.push({ name: 'dealStatus', value: status })
  else filters.push({ name: 'dealStatus', value: 'Archived', operator: 'ne' })

  const fitlersAsString = buildfiltersString(filters, ['code', 'name', 'source/key'])
  if (fitlersAsString) list.push(fitlersAsString)

  if (organizationId) list.push(`organizationId=${organizationId}`)

  let endpoint = `${VUE_APP_CAMPSITE_API_URL}/deals/`
  if (list.length) endpoint += `?${list.join('&')}`

  return endpoint
}

/**
 * TODO: move this into a shared service cause it's the same as the one in creativesAPI (!)
 */
function buildfiltersString (filtersIn = [], searchColumns = ['name']) {
  var filtersOut = []
  var filtersString = ''

  filtersIn.map(filter => {
    // SEARCH
    if (filter.name === 'search') {
      const escapedFilter = helpers.replaceSpecialCharacters(filter.value)
      filtersOut.push('(' + searchColumns.map(x => `contains(${x}, '${escapedFilter}')`).join(' or ') + ')')
    } else {
      const operator = filter.operator || 'eq'
      const quotedValue = typeof filter.value === 'number' ? filter.value : "'" + filter.value + "'"
      const filterString = filter.name + ' ' + operator + ' ' + quotedValue
      filtersOut.push(filterString)
    }
  })

  if (filtersOut.length) {
    filtersString = '$filter=' + filtersOut.join(' and ')
  }

  return filtersString
}
