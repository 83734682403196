<template lang="pug">
  v-expansion-panel#cost-panel
    v-expansion-panel-header
      template(v-slot:default='{ open }')
        v-row.my-0(no-gutters align='center')
          v-col.text-h6(cols='4') Cost
    v-expansion-panel-content(eager ref='cost-panel-content')
      fee(
        type='Percentage'
        label='Media'
        suffix='%'
        :fees='mediaFees'
        :defaultFee='defaultFees.filter(f => f.type === "Percentage")'
        :isVisible='true'
        :refreshKey='refreshKey'
        @addFee='addFee'
        @deleteFee='deleteFee'
        @updateValue='updateValue'
        @saveFeesInStore='saveFeesInStore'
        @updateLabel='updateLabel'
      )
      fee(
        type='Cpm'
        label='Cpm'
        :suffix='currencySymbol'
        :fees='cpmFees'
        :defaultFee='defaultFees.filter(f => f.type === "Cpm")'
        :isVisible='canSeeNewAuctionPackageCpmFee'
        :refreshKey='refreshKey'
        @addFee='addFee'
        @deleteFee='deleteFee'
        @updateValue='updateValue'
        @saveFeesInStore='saveFeesInStore'
        @updateLabel='updateLabel'
      )
</template>

<script>
import helpers from '@/services/helpers.service'
import flags from '@/plugins/rox/flags'
import defaultCurrencyValues from '@/services/defaultCurrencyValues'
import fee from '@/components/auctionPackages/fees/fee.vue'

export default {
  components: {
    fee
  },
  props: {
    existingFees: {
      type: Array
    }
  },
  data () {
    return {
      rules: {
        positive: v => v >= 0 || 'Positive numbers only'
      },
      defaultFees: [{
        type: 'Percentage',
        label: 'Broadsign Ads Fee',
        layoutOrder: 1,
        value: 5
      }],
      fees: [],
      refreshKey: 1
    }
  },
  created () {
    if (this.canSeeNewAuctionPackageCpmFee) {
      this.defaultFees.push({
        type: 'Cpm',
        label: 'Broadsign Ads Fee',
        layoutOrder: 1,
        value: 0
      })
    }

    this.fees = !this.existingFees
      ? JSON.parse(JSON.stringify(this.defaultFees))
      : JSON.parse(JSON.stringify(this.existingFees.map(ef => { return { ...ef, value: ef.type === 'Cpm' ? ef.value : 100 * ef.value } })))

    this.saveFeesInStore()
  },
  computed: {
    mediaFees () {
      // eslint-disable-next-line no-unused-expressions
      this.refreshKey
      return this.fees
        .filter(f => f.type === 'Percentage')
        .sort((a, b) => a.layoutOrder - b.layoutOrder)
        .map(f => {
          return {
            ...f,
            displayedValue: f.value || null,
            secondaryLabel: `Media Fee ${f.layoutOrder}`,
            isLabelFixed: f.layoutOrder === 1,
            cssClass: `media-fee-${f.layoutOrder}`
          }
        })
    },
    cpmFees () {
      // eslint-disable-next-line no-unused-expressions
      this.refreshKey
      return this.fees
        .filter(f => f.type === 'Cpm')
        .sort((a, b) => a.layoutOrder - b.layoutOrder)
        .map(f => {
          return {
            ...f,
            displayedValue: f.value || null,
            secondaryLabel: `CPM Fee ${f.layoutOrder}`,
            isLabelFixed: f.layoutOrder === 1,
            cssClass: `cpm-fee-${f.layoutOrder}`
          }
        })
    },
    canSeeNewAuctionPackageCpmFee () {
      return flags.canSeeNewAuctionPackageCpmFee.isEnabled()
    },
    currencySymbol () {
      const audienceCurrencyCode = this.$store.getters['audience/getCurrency']?.code
      return flags.canSeeAuctionPackageCurrencySelection.isEnabled() && audienceCurrencyCode
        ? defaultCurrencyValues(audienceCurrencyCode).currencySymbolString
        : '$'
    }
  },
  methods: {
    saveFeesInStore () {
      const formattedFees = this.formatFeesForApi(this.fees.filter(f => f.value == null || f.value >= 0))
      this.$store.commit('auctionPackage/setFees', formattedFees)
    },
    updateValue (position, type, e) {
      const value = e.target.value >= 0 ? e.target.value : 0
      const index = this.fees.findIndex(f => f.type === type && f.layoutOrder === position)
      this.fees[index].value = helpers.trimNumber(value, 2)
      this.saveFeesInStore()
      this.refreshKey++
    },
    updateLabel (position, type, e) {
      const index = this.fees.findIndex(f => f.type === type && f.layoutOrder === position)
      const label = e.target.value

      this.fees[index].label = label
      this.saveFeesInStore()
    },
    addFee (type) {
      var typedFees = this.fees.filter(f => f.type === type)
      if (typedFees?.length >= 5) return

      if (!typedFees?.length) {
        this.fees.push(...JSON.parse(JSON.stringify(this.defaultFees.filter(f => f.type === type))))
        this.saveFeesInStore()
        return
      }

      this.fees.push({
        type,
        label: '',
        layoutOrder: this.deduceNextFeePosition(typedFees),
        value: null
      })
    },
    deleteFee (position, type) {
      const index = this.fees.findIndex(f => f.type === type && f.layoutOrder === position)
      this.fees.splice(index, 1)
      this.saveFeesInStore()
    },
    formatFeesForApi (fees) {
      return fees.map(f => {
        if (f.type === 'Cpm') {
          return {
            ...f,
            value: f.value ? helpers.trimNumber(f.value, 2) : 0
          }
        }
        return {
          layoutOrder: f.layoutOrder,
          type: f.type,
          label: f.label,
          value: f.value ? helpers.trimNumber(f.value / 100, 4) : 0
        }
      })
    },
    deduceNextFeePosition (fees) {
      for (let i = 0; i < fees.length; i++) {
        if ((i + 1) !== fees[i].layoutOrder) return i + 1
      }

      return fees[fees.length - 1].layoutOrder + 1
    }
  }
}
</script>
