import defaultCurrencyValues from '@/services/defaultCurrencyValues'
import accounting from 'accounting-js'

export default {
  getFloorCPMsLabel,
  formatFloorCPM
}

function getFloorCPMsLabel (floorCPMs, separator = ', ') {
  if (!floorCPMs) return ''
  var label = ''
  floorCPMs.forEach((floorCPM) => {
    if (label !== '') { label += separator }
    label += formatFloorCPM(floorCPM)
    label += ' ' + floorCPM.currency
  })
  return label
}

function formatFloorCPM (floorCPM) {
  return accounting.formatMoney(floorCPM.floorCPM, { symbol: defaultCurrencyValues(floorCPM.currency).currencySymbolString, precision: 2 })
}
