import axios from 'axios'
import helpers from '@/services/helpers.service'

var { VUE_APP_CAMPSITE_API_URL } = process.env

export default {
  getCreatives,
  getCreativesCount,
  getCreative,
  getCreativeThumbnail,
  uploadCreative,
  editCreative
}

function getCreatives (skip = 0, take = 5, orderBy = 'id', order = 'desc', filters = []) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/creatives?skip=' + skip + '&take=' + take + '&$orderby=' + orderBy + ' ' + order

  var filtersString = buildfiltersString(filters)
  if (filtersString) {
    endpoint += '&' + filtersString
  }

  return axios.get(endpoint).then(response => response.data)
}

function getCreativesCount (filters = []) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/creatives'

  var filtersString = buildfiltersString(filters)
  if (filtersString) {
    endpoint += '?' + filtersString
  }
  return axios.head(endpoint).then(response => parseInt(response.headers['x-count']) || 0)
}

function getCreative (creativeId) {
  return axios.get(VUE_APP_CAMPSITE_API_URL + '/creatives?$filter=id eq ' + creativeId).then(res => res.data[0])
}

function buildfiltersString (filtersIn = []) {
  var filtersOut = []
  var filtersString = ''

  filtersIn.map(filter => {
    const operator = filter.operator || 'eq'
    let filterString = ''

    const escapedFilter = parseInt(filter.value) ? filter.value : helpers.replaceSpecialCharacters(filter.value)
    const join = operator === 'eq' ? ' or ' : ' and '
    const quotedValue = typeof filter.value === 'number' ? filter.value : "'" + filter.value + "'"

    switch (filter.name) {
      // SEARCH
      case 'search':
        filtersOut.push("(contains(name, '" + escapedFilter + "') or contains(advertiser/name, '" + escapedFilter + "'))")
        break
      // creatives IDS
      case 'status':
        filtersOut.push(filter.keyName + ' ' + operator + ' ' + "'" + filter.value + "'")
        break
      case 'creativeIds':
        filterString = filter.value.map(c => 'id ' + operator + ' ' + c).join(join)
        filtersOut.push(filterString)
        break
      default:
        filterString = filter.name + ' ' + operator + ' ' + quotedValue
        filtersOut.push(filterString)
    }
  })

  if (filtersOut.length) {
    filtersString = '$filter=' + filtersOut.join(' and ')
  }
  return filtersString
}

function getCreativeThumbnail (creativeId, dimensions) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/creatives/' + creativeId + '/thumbnail/' + dimensions
  return axios.get(endpoint)
    .then(response => response.data.url)
    .catch(() => {
      return null
    })
}

function uploadCreative (formData, onUploadProgressCallback) {
  const endpoint = VUE_APP_CAMPSITE_API_URL + '/creatives/add'

  const config = {
    onUploadProgress: onUploadProgressCallback
  }

  return axios.post(endpoint, formData, config)
    .then(response => response.data)
    .catch(error => {
      var errMsg = error.response.data.errors[0].message

      // status 400 (Bad Request) is returned in 2 cases :
      //  1. Creative format is not supported, i.e. file type not accepted (ex: .gif)
      //  2. Bad request, i.e. creativeFormat not accepted (ex: 200x200)
      if (error.response.status >= 400 && error.response.status < 500) {
        errMsg = errMsg === 'Bad Request' ? 'Creative Format not accepted' : 'File Type not supported'
      }

      return { error: errMsg }
    })
}

function editCreative (creative) {
  var endpoint = VUE_APP_CAMPSITE_API_URL + '/creatives/' + creative.id

  // TODO: validate with David that this still holds...
  if (creative.processStatus === 'New') {
    endpoint += '?ready=1'
  }

  return axios.patch(endpoint, creative)
    .then(response => response.data)
}
