import defaultExchangeValues from './defaultExchangeValues'

const INVENTORY_MARKET_KEY = 'inventoryMarket'

function getMarket () {
  const cachedMarket = localStorage.getItem(INVENTORY_MARKET_KEY)

  if (!cachedMarket || isValidMarket(cachedMarket)) return cachedMarket

  console.error(`Local storage contains invalid market: ${cachedMarket}`)
  return null
}

function saveMarket (market) {
  localStorage.setItem(INVENTORY_MARKET_KEY, market)
}

function isValidMarket (market) {
  return defaultExchangeValues.getAllDefaultValues().some(ev => ev.market === market)
}

export default {
  getMarket,
  saveMarket
}
