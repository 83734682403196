<template lang="pug">
v-layout.py-0(row wrap)
  v-flex.pb-0(xs12 lg3 xl4 text-xl-right mt-3)
    .text-body-1.font-weight-bold.mr-3.currency-label Currencies
  v-flex(xs12 lg9 xl8 text-md-left)
    v-chip-group.currency-option-group(v-model='selectedCurrency' active-class='primary' column mandatory)
      v-chip.currency-option.mx-1(v-for='market in uniqueCurrencies' :key="market.currency" :value='market.currency') {{ market.currency }}
</template>
<script>

import defaultCurrencyValues from '@/services/defaultCurrencyValues'

export default {
  props: {
    currency: {
      type: String,
      default: () => 'USD'
    }
  },
  data () {
    return {
      selectedCurrency: this.currency
    }
  },
  mounted: function () {
    this.updateSelectedCurrency(this.selectedCurrency)
  },
  computed: {
    uniqueCurrencies () {
      return Object.values(defaultCurrencyValues())
        .filter(currency => currency.currency !== 'GBP')
        .sort((x, y) => x.displayOrder - y.displayOrder)
    }
  },
  watch: {
    selectedCurrency (newV, oldV) {
      if (newV !== oldV) {
        this.updateSelectedCurrency(newV)
        this.$emit('storeUpdated')
      }
    }
  },
  methods: {
    updateSelectedCurrency (currency) {
      this.$store.commit('audience/setCurrency', { code: currency })
    }
  }
}
</script>
