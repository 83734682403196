import campsiteConfig from '@/config/campsite.config'
import { OrganizationTypeEnum } from '@/enums'
import audienceApi from '@/services/audience.api'
import campaignsApi from '@/services/campaigns.api'
import audienceService from '../services/audience.service'
import defaultExchangeValues from '@/services/defaultExchangeValues'
import geoService from '@/services/geo.service'
import helpers from '@/services/helpers.service'
import reportingApi from '@/services/reporting.api'
import userApi from '@/services/user.api'
import flags from '@/plugins/rox/flags'

import Vue from 'vue'

function lineDetailsTemplate () {
  return {
    forecasts: {
      // TBD - do in resetAudience() also (FACTORY function)
      bidRanges: [
        {
          cpmps: 0,
          percentile: 10
        },
        {
          cpmps: 0,
          percentile: 99
        }
      ],
      environments: [],
      formats: [],
      impressions: {
        value: 0
      },
      inventory: {
        numberOfScreens: 0,
        numberOfVenues: 0
      },
      spending: [
        {
          percentile: 10,
          amount: 0.000
        },
        {
          percentile: 99,
          amount: 0.000
        },
        {
          percentile: 100,
          amount: 2492.600
        }
      ],
      budget: {
        environments: [],
        impressions: {
          value: 0
        },
        spending: [
          {
            percentile: 10,
            amount: 0.000
          },
          {
            percentile: 99,
            amount: 0.000
          },
          {
            percentile: 100,
            amount: 2492.600
          }
        ]
      }
    },
    isLoadingForecast: true,
    isLoadingForecastInventory: true,
    forecastInventory: {
      positions: []
    },
    filterAsMapMoves: true,
    mapSettings: null,
    mapInitialSettings: null,
    isLoadingVenues: true,
    venues: [],
    pagination: {
      sortBy: ['environment'],
      sortDesc: [false],
      page: 1,
      take: 10,
      skip: 0,
      query: null,
      filtering: false,
      hasMorePages: false
    },
    activeTab: 0,
    commonForecastOptions: {}
  }
}

export default {
  namespaced: true,
  state: {
    isLoadingProposal: true,
    idLineToBeOpenedNext: null,
    forecastForNewProposal: {
      proposalId: null,
      forecast: null
    },
    proposal: null,
    advertiser: null,
    lines: [],
    details: {},
    loadingToken: false,
    buyerMarkup: 0
  },

  getters: {
    marketValues: state => {
      return state.proposal
        ? defaultExchangeValues.getDefaultValuesByCurrency(state.proposal.currency)
        : { currencySymbolString: '', distanceUnit: '' }
    },
    advertiser: state => {
      return state.advertiser
    },
    proposedBySentence: state => {
      if (!state.proposal) return ''

      let name = ''
      if (state.proposal.owner.firstName) name += state.proposal.owner.firstName + ' '
      if (state.proposal.owner.lastName) name += state.proposal.owner.lastName + ' '

      return `Proposed by ${name ? name + 'from ' : ''}${state.proposal.owner.organizationName}`
    },
    duration: state => {
      if (!state.proposal) return 'N/A'
      if (!state.proposal.startDate || !state.proposal.endDate) return 'N/A'
      return helpers.readableDateDuration(state.proposal.startDate, state.proposal.endDate)
    },
    totalBudget: (state, getters) => {
      const total = state.lines.reduce((sum, { budget }) => sum + budget, 0)
      return helpers.formatMoney(total, 0, getters.marketValues.currencySymbolString)
    },
    totalImpressions: state => {
      const linesBudgeted = state.lines.map(x => {
        const forecastImpressions = state.details?.[x.id]?.forecasts?.budget?.impressions?.value
        if (forecastImpressions) {
          return { budgeted: { impressions: { from: forecastImpressions, to: forecastImpressions } } }
        }
        return !x.budgeted ? null : x
      })

      if (linesBudgeted.some(x => !x)) return 'N/A'
      if (linesBudgeted.every(l => l.momentId != null)) return 'N/A'

      const fromTotal = linesBudgeted
        .map(x => x.momentId ? 0 : x.budgeted.impressions.from)
        .reduce((sum, current) => sum + current, 0)

      const toTotal = linesBudgeted
        .map(x => x.momentId ? 0 : x.budgeted.impressions.to)
        .reduce((sum, current) => sum + current, 0)

      return fromTotal === toTotal
        ? helpers.shortenNumber(fromTotal)
        : `${helpers.shortenNumber(fromTotal)} - ${helpers.shortenNumber(toTotal)}`
    },
    totalTargetImpressions: state => {
      const total = state.lines
        .map(line => {
          if (!line.maxCpm) return 0
          return line.budget / line.maxCpm * 1000
        })
        .reduce((sum, current) => sum + current, 0)

      return helpers.shortenNumber(total)
    },
    shareLink: state => {
      if (state.proposal) {
        const id = state.proposal.id
        const tokens = state.proposal.tokens
        return tokens.length ? `${window.location.origin}/proposals/${id}?token=${tokens[0].token}` : 'Loading'
      }
    },
    lineToBeOpenedNext: state => {
      const i = state.lines.findIndex(x => x.id === state.idLineToBeOpenedNext)
      return !state.lines.length || i < 0 ? 0 : i
    },
    lineDetails: state => lineId => {
      return state.details[lineId]
    }
  },

  mutations: {
    resetStore (state) {
      state.isLoadingProposal = true
      state.proposal = null
      state.advertiser = null
      state.lines = []
      state.details = {}
    },
    setProposal (state, campaign) {
      state.proposal = campaign
    },
    setLines (state, lines) {
      state.lines = lines
    },
    setLineToBeOpenedNextId (state, id) {
      state.idLineToBeOpenedNext = id
    },
    updateLine (state, newLine) {
      const i = state.lines.findIndex(x => x.id === newLine.id)
      if (i > -1) {
        state.lines.splice(i, 1, newLine)
        Vue.set(state.details, newLine.id, lineDetailsTemplate())
      }
    },
    addLine (state, line) {
      Vue.set(state.details, line.id, lineDetailsTemplate())
      state.lines.push(line)
      state.idLineToBeOpenedNext = line.id
      if (state.proposal && state.lines.length === 1) {
        state.proposal.startDate = line.startDate
        state.proposal.endDate = line.endDate
      }
    },
    removeLine (state, lineId) {
      var lineIndex = state.lines.findIndex(l => l.id === lineId)
      if (lineIndex > -1) {
        state.lines.splice(lineIndex, 1)
      }

      delete state.details[lineId]
    },
    setLoading (state, bool) {
      state.isLoadingProposal = bool
    },
    setAdvertiser (state, advertiser) {
      state.advertiser = advertiser
    },
    initializeDetails (state, lines) {
      lines.forEach(line => {
        Vue.set(state.details, line.id, lineDetailsTemplate())
      })
    },
    updateLineDetail (state, data) {
      state.details[data.lineId][data.field] = data.value
    },
    updateMapTableSync (state, lineId) {
      const current = state.details[lineId].filterAsMapMoves
      state.details[lineId].filterAsMapMoves = !current
    },
    updateLinePagination (state, data) {
      const linePagination = state.details[data.lineId].pagination
      const newLinePagination = data.pagination

      const page = newLinePagination.page || linePagination.page
      const take = newLinePagination.take || linePagination.take
      newLinePagination.skip = (page - 1) * take

      state.details[data.lineId].pagination = Object.assign({}, linePagination, newLinePagination)
    },
    setLoadingToken (state, bool) {
      state.loadingToken = bool
    },
    setShareToken (state, tokenObj) {
      state.proposal.tokens.push(tokenObj)
    },
    saveImpressions (state, obj) {
      state.forecastForNewProposal = obj
    },
    clearSavedImpressions (state) {
      state.forecastForNewProposal.proposalId = null
      state.forecastForNewProposal.forecast = null
    },
    updateOrganization (state, orgName) {
      state.proposal.owner.organizationName = orgName
    },
    updateProposal (state, proposal) {
      if (proposal.name) {
        state.proposal.name = proposal.name
      }

      if (proposal.accountId) {
        state.proposal.accountId = proposal.accountId
      }

      if (proposal.account) {
        state.proposal.account = proposal.account
      }
    },
    setLineActiveTab (state, data) {
      var { lineId, tab } = data
      state.details[lineId].activeTab = tab
    },

    setChartLoading (state, obj) {
      state.details[obj.lineId].isLoadingForecast = obj.isLoading
    },

    updateLineForecasts (state, obj) {
      const { lineId, forecasts, forecastInventory } = obj

      const details = state.details[lineId]

      details.forecasts = Object.assign({}, details.forecasts, forecasts)
      details.forecastInventory = Object.assign({}, details.forecastInventory, forecastInventory)

      details.isLoadingForecastInventory = false
      details.isLoadingForecast = false
    },

    updateLineCommonForecastOptions (state, data) {
      const { lineId, lineCommonForecastOptions } = data
      state.details[lineId].commonForecastOptions = lineCommonForecastOptions
    },
    updateLineMapSettings (state, data) {
      const { lineId, mapSettings } = data
      state.details[lineId].mapSettings = mapSettings

      if (!state.details[lineId].mapInitialSettings) {
        state.details[lineId].mapInitialSettings = mapSettings
      }
    },
    setBuyerMarkup (state, markup) {
      state.buyerMarkup = markup
    }
  },
  actions: {
    fetch ({ commit, dispatch, state, rootGetters }, proposalId) {
      return reportingApi.getCampaign(proposalId)
        .then(campaign => {
          if (campaign.status !== 'Proposal') {
            return '404'
          }
          commit('setProposal', campaign)

          if (rootGetters['auth/isLoggedIn']) {
            dispatch('getBuyerMarkup')
          }

          const accountId = campaign.accountId
          const campaignId = campaign.id

          reportingApi.getLines({ accountId, campaignId })
            .then(lines => {
              const activeLines = lines.data.filter(line => line.bookingStatus !== 'Archived')
              commit('setLines', activeLines)
              commit('initializeDetails', activeLines)

              const advertiser = {
                id: campaign.accountId,
                name: campaign.account,
                buyerId: campaign.buyerId
              }
              commit('setAdvertiser', advertiser)

              commit('setLoading', false)

              if (activeLines.length) {
                // "geography" not returned by /lines anymore,
                // so "line" prop won't have it attached
                // query API to get it before loading audience
                // Load first line's forecast
                var firstLine = activeLines[0]
                dispatch('getLine', firstLine.id)
                  .then(lineWithGeo => {
                    dispatch('getLineForecast', lineWithGeo.id)
                  })
              }
            })
        })
    },

    getBuyerMarkup ({ commit, state }) {
      userApi.getOrganization(state.proposal.buyerId)
        .then(buyerOrg => {
          commit('setBuyerMarkup', buyerOrg.markup)
        })
    },

    getLine ({ commit, state, dispatch }, lineId) {
      commit('updateLineDetail', { lineId, field: 'isLoading', value: true })

      var ids = {
        accountId: state.proposal.accountId,
        campaignId: state.proposal.id,
        lineId
      }

      return reportingApi.getLine(ids, true)
        .then(line => {
          line.geoLoaded = true
          commit('updateLine', line)
          commit('updateLineDetail', { lineId: line.id, field: 'isLoading', value: false })

          return line
        })
    },

    getLineForecast ({ commit, state, dispatch, rootGetters }, lineId) {
      commit('updateLineDetail', { lineId, field: 'isLoadingForecast', value: true })
      const line = state.lines.find(x => x.id === lineId)

      dispatch('buildCommonForecastOptions', lineId)

      const options = Object.assign({}, state.details[lineId].commonForecastOptions, {
        budget: {
          amount: line.budget,
          maxcpmps: line.maxCpm / campsiteConfig.creatives.defaultDuration.image
        }
      })

      const assignments = rootGetters['campaignReport/getAssignments']
      if (assignments.length) {
        options.creatives = assignments
          // filter assignments for this Line only
          .filter(assignment => assignment.lineId === parseInt(lineId))
          .map(assignment => {
            var creative = assignment.creative
            return {
              creativeId: creative.id,
              creativeFormatId: creative.creativeFormat.id,
              creativeDuration: creative.duration
            }
          })
      }

      audienceApi.getForecast(options).then(resp => {
        if (!resp) return

        // budget object is not returned (null) instead of being returned empty {}
        // which will overwrite ou initial State
        const forecast = resp.data
        if (!forecast.budget) {
          delete forecast.budget
        }
        forecast.bidRanges = audienceService.GetMinMaxForecastBidRange(resp.data, forecast)

        commit('updateLineDetail', { lineId, field: 'forecasts', value: forecast })
        commit('updateLineDetail', { lineId, field: 'isLoadingForecast', value: false })
      })
    },

    buildCommonForecastOptions ({ commit, state, getters, rootGetters }, lineId) {
      const line = state.lines.find(x => x.id === lineId)
      const exchange = rootGetters['general/getExchanges'].find(e => e.id === line.exchangeId)
      const options = {
        mapId: lineId,
        buyerId: state.advertiser.buyerId,
        exchange: exchange.key,
        targeting: line.targeting,
        startDate: line.startDate,
        endDate: line.endDate,
        returnNativeResolution: line.planLine?.mergeCreativeFormatRatio !== undefined ? !line.planLine?.mergeCreativeFormatRatio : null,
        currency: flags.canSeeAuctionPackageCurrencySelection.isEnabled() ? line.currency?.code : null
      }

      if (state.proposal.organizationType === OrganizationTypeEnum.PARTNER_DSP) {
        options.usePublicExchange = line.usePublicExchange
        options.percentiles = [1, 100]
      } else {
        options.usePublicExchange = null
      }

      if (line.geography && Object.keys(line.geography).length > 0) {
        const distanceUnit = getters.marketValues.distanceUnit
        options.geography = removeGeoJSON(line.geography, distanceUnit)
      }

      if (line.segments && line.segments.groups && line.segments.groups.length > 0) {
        options.segments = line.segments
      }

      if (line.deals.length) {
        options.deals = line.deals.map(x => x.code)
      }

      commit('updateLineCommonForecastOptions', { lineId, lineCommonForecastOptions: options })
    },

    fetchVenues ({ commit, dispatch, state }, lineId) {
      commit('updateLineDetail', { lineId: lineId, field: 'isLoadingVenues', value: true })

      dispatch('buildCommonForecastOptions', lineId)

      const lineDetails = state.details[lineId]

      const mapSettings = lineDetails.pagination.filtering
        ? lineDetails.mapInitialSettings
        : lineDetails.mapSettings

      const forecastVenuesOptions = Object.assign({}, lineDetails.commonForecastOptions, { map: mapSettings })

      audienceApi.getForecastVenues(forecastVenuesOptions, state.details[lineId].pagination).then(res => {
        if (!res) return

        commit('updateLineDetail', { lineId: lineId, field: 'venues', value: res.data.venues })
        commit('updateLineDetail', { lineId: lineId, field: 'isLoadingVenues', value: false })
        commit('updateLinePagination', {
          lineId: lineId,
          pagination: {
            hasMorePages: res.headers['x-has-more-rows'] === 'true'
          }
        })
      })
    },

    getInventoryForecast ({ state, commit, dispatch }, lineId) {
      commit('updateLineDetail', { lineId: lineId, field: 'isLoadingForecastInventory', value: true })

      dispatch('buildCommonForecastOptions', lineId)

      const lineDetails = state.details[lineId]

      const mapSettings = lineDetails.pagination.filtering
        ? lineDetails.mapInitialSettings
        : lineDetails.mapSettings

      const forecastInventoryOptions = Object.assign({}, lineDetails.commonForecastOptions, { map: mapSettings })

      audienceApi.getForecastInventory(forecastInventoryOptions, lineDetails.pagination)
        .then(res => {
          if (!res) return

          commit('updateLineDetail', { lineId: lineId, field: 'forecastInventory', value: res.data })
          commit('updateLineDetail', { lineId: lineId, field: 'isLoadingForecastInventory', value: false })
        })
    },

    updatePagination ({ commit, dispatch }, obj) {
      commit('updateLinePagination', obj)
      // dispatch('loadInventory', { source: 'table', lineId: obj.lineId })
    },

    loadInventory ({ dispatch, state }, obj) {
      const lineDetails = state.details[obj.lineId]

      if (obj.source === 'table') {
        dispatch('fetchVenues', obj.lineId)

        if (lineDetails.pagination.filtering) {
          dispatch('getInventoryForecast', obj.lineId)
        }
      } else {
        // else = Map
        dispatch('getInventoryForecast', obj.lineId)

        if (lineDetails.filterAsMapMoves && lineDetails.activeTab === 1) {
          dispatch('fetchVenues', obj.lineId)
        }
      }
    },

    removeLineFromProposal ({ commit, state }, lineId) {
      const ids = {
        accountId: state.proposal.accountId,
        orderId: state.proposal.id,
        lineId
      }

      return reportingApi.editLinePatch(ids, { bookingStatus: 'Archived', orderId: state.proposal.id })
        .then(resp => {
          if (resp.status && resp.status === 200) {
            commit('removeLine', lineId)
            return true
          } else {
            return false
          }
        })
    },

    duplicateProposalLine ({ commit, state }, lineId) {
      const ids = {
        accountId: state.proposal.accountId,
        orderId: state.proposal.id,
        lineId
      }
      return reportingApi.duplicateLine(ids)
        .then(resp => {
          const duplicatedLine = resp.data
          commit('addLine', duplicatedLine)
          return duplicatedLine
        })
    },

    transfer ({ commit, dispatch }, data) {
      var currentAccountId = data.accountId

      var accountIdPromise = data.organizationId && data.accountName
        ? dispatch('getOrCreateAdvertiserInOrganization', data)
        : new Promise((resolve, reject) => {
          resolve(data.accountId)
        })

      return accountIdPromise.then(accountId => {
        const modifiedFields = {
          id: data.proposalId,
          accountId: accountId
        }

        return campaignsApi.editCampaign(currentAccountId, data.proposalId, modifiedFields).then(updatedCampaign => {
          if (updatedCampaign) commit('updateOrganization', updatedCampaign.owner.organizationName)
          return updatedCampaign
        })
      })
    },

    convertToCampaign ({ state }) {
      const modifiedFields = {
        id: state.proposal.id,
        status: 'Active'
      }
      return campaignsApi.editCampaign(state.proposal.accountId, state.proposal.id, modifiedFields).then(updatedCampaign => updatedCampaign)
    },

    getOrCreateAdvertiserInOrganization (context, data) {
      // create Advertiser account in Organization, if it doesn't exists
      return campaignsApi.getAdvertiserInOrganization(data.accountName, data.organizationId)
        .then(resp => {
          if (resp.length) {
            return resp[0].id
          } else {
            const organization = {
              name: data.accountName,
              ownerOrganizationId: data.organizationId
            }

            return campaignsApi.createOrganization(organization)
              .then(organizationCreated => {
                const account = {
                  advertiserId: organizationCreated.id,
                  buyerId: data.organizationId,
                  name: data.accountName
                }

                return campaignsApi.createAccount(account)
                  .then(accountCreated => accountCreated.id)
              })
          }
        })
    },

    archive ({ state }) {
      const { id, accountId } = state.proposal
      const modifiedFields = {
        id,
        status: 'Archived'
      }
      return campaignsApi.editCampaign(accountId, id, modifiedFields).then(res => res)
    },

    updateProposal ({ state, commit, rootGetters }, data) {
      return campaignsApi.editCampaign(state.proposal.accountId, state.proposal.id, data)
        .then(res => {
          if (data.accountId) {
            data.account = res.account

            const advertiser = {
              id: data.accountId,
              name: data.account,
              buyerId: state.proposal.buyerId
            }
            commit('setAdvertiser', advertiser)
          }

          commit('updateProposal', data)

          return res
        })
    },

    duplicate ({ state }) {
      const { id, accountId } = state.proposal
      return campaignsApi.duplicateCampaign(accountId, id, false)
    },

    generateToken ({ state, commit }) {
      if (!state.proposal.tokens.length) {
        commit('setLoadingToken', true)
        campaignsApi.generateToken(state.proposal.id, state.proposal.accountId).then(res => {
          commit('setShareToken', res)
          commit('setLoadingToken', false)
        })
      }
    },

    saveOpenedPanelId ({ commit }, id) {
      commit('setLineToBeOpenedNextId', id)
      return new Promise(resolve => setTimeout(() => resolve(), 0))
    }
  }
}

function removeGeoJSON (geography, distanceUnit = 'metric') {
  // do NOT send geoJSON(s) to Forecast for performance
  // easier to go through "selectedGeoTargets" and re-format
  // than trying to go through already formated "geography"
  var selectedGeoTargets = geoService.geoFromApiFormatting(geography, distanceUnit)
  var selectedGeoTargetsNoGeoJSON = geoService.removeGeoJSON(selectedGeoTargets, distanceUnit)
  return geoService.geoTargetsToApiFormatting(selectedGeoTargetsNoGeoJSON)
}
