<template lang='pug'>
  v-card.my-6(:loading='pageLoading')
    v-card-title.text-h5 Inventory Access
    v-card-text.text--primary
      | Inventory source that every advertiser and campaign will have access by default. These parameters can be overwritten when creating a campaign.

    inventory-deals(
      :deals='deals'
      :deals-loading='gettingDeals'
      :parentOrganizationId='organizationId'
      @dealAdded='dealAdded'
      @dealRemoved='dealRemoved')

    v-divider
    v-card-actions
      v-spacer
      v-btn.save-inventory(color='primary' text :disabled='!dealsHaveChanged' :loading='saving' @click='save') Save Changes
</template>

<script>
import inventoryDeals from '@/components/inventoryDeals.vue'
import userApi from '@/services/user.api'
import trackingService from '@/services/tracking'

export default {
  components: {
    inventoryDeals
  },
  props: {
    pageLoading: {
      type: Boolean,
      default: () => false
    },
    organizationId: {
      type: Number,
      default: () => 0
    }
  },
  data () {
    return {
      gettingDeals: true,
      deals: [],
      initialDeals: [],
      saving: false
    }
  },
  computed: {
    addedDeals () {
      return this.deals.filter(deal => this.initialDeals.findIndex(initialDeal => initialDeal.code === deal.code) === -1)
    },
    removedDeals () {
      return this.initialDeals.filter(initialDeal => this.deals.findIndex(deal => deal.code === initialDeal.code) === -1)
    },
    dealsHaveChanged () {
      return this.addedDeals.length > 0 || this.removedDeals.length > 0
    }
  },
  mounted () {
    this.getOrganizationDeals()
  },
  methods: {
    getOrganizationDeals () {
      this.gettingDeals = true

      userApi.getOrganizationDeals(this.organizationId)
        .then(deals => {
          this.deals = deals
          this.initialDeals = [...deals]
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.gettingDeals = false
        })
    },
    dealAdded (deal) {
      this.deals.unshift(deal)
    },
    dealRemoved (deal) {
      const i = this.deals.findIndex(d => d.code === deal.code)
      if (i > -1) {
        this.deals.splice(i, 1)
      }
    },
    save () {
      this.saving = true

      trackingService.sendEvent(['ga'], 'savedDeals')

      const addPromises = this.addedDeals.map(addedDeal => {
        return userApi.addOrganizationDeal(this.organizationId, addedDeal.code)
      })

      const removePromises = this.removedDeals.map(removedDeal => {
        return userApi.deleteOrganizationDeal(this.organizationId, removedDeal.code)
      })

      Promise.all([...addPromises, ...removePromises])
        .then(resp => {
          this.setSnackbar('Deals updated')
        })
        .catch(err => {
          this.setSnackbar(err)
        })
        .finally(() => {
          this.saving = false
          this.getOrganizationDeals()
        })
    },
    setSnackbar (param) {
      let type = 'success'
      let msg = ''

      if (typeof param === 'string') {
        msg = param
      } else {
        type = 'error'
        try {
          const error = param.response.data.errors[0]
          msg = error.message
        } catch {
          msg = param.toString()
        }
      }

      this.$store.commit('snackbar/setSnackbar', { type, msg })
    }
  }
}
</script>
